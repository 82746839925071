import React, { useState, useEffect } from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import Title from '../components/Title'
import moduleIcon from '../assets/moduleIcon.png'
import askIcon from '../assets/askIcon.png'
// import testBackground from '../assets/testBackground.png';
// import materialsImage from '../assets/materialsImage.png';
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

const Module = () => {
  const navigate = useNavigate()

  // const [moduleData, setModuleData] = useState([])
  const [materialsData, setMaterialsData] = useState([])

  const userId = useSelector(state => state.user.user?.data.id)

  // useEffect(() => {
  //   const fetchModuleData = async () => {
  //     try {
  //       if (!userId) {
  //         console.error('User information is not available.')
  //         return
  //       }

  //       const response = await axios.post('/api/module/getModule', {
  //         UserId: userId
  //       })

  //       const data = response.data

  //       if (response.status === 200) {
  //         setModuleData(data.data)
  //       } else {
  //         console.error('Failed to fetch module data')
  //       }
  //     } catch (error) {
  //       console.error('Error fetching module data:', error)
  //     }
  //   }

  //   fetchModuleData()
  // }, [userId])

  useEffect(() => {
    const fetchMaterialsData = async () => {
      try {
        const response = await axios.post('/api/module/getMaterials', {})

        const data = response.data

        if (response.status === 200) {
          const Growth = data.data.find(material =>
            material.category.startsWith('Growth')
          )
          const Lectures = data.data.find(material =>
            material.category.startsWith('Lectures')
          )
          const Webinar = data.data.find(material =>
            material.category.startsWith('Webinar')
          )
          const dataCap = [Growth, Lectures, Webinar]

          setMaterialsData(dataCap)
        } else {
          console.error('Failed to fetch materials data')
        }
      } catch (error) {
        console.error('Error fetching materials data:', error)
      }
    }

    if (userId) {
      fetchMaterialsData()
    }
  }, [userId]) // Include an empty dependency array to fetch materials only once when the component mounts

  console.log('materialsData ---', materialsData)

  return (
    <div>
      <Header />
      <Title text="E-Learning" />

      <div
        style={{
          margin: 'auto',
          justifyContent: 'center',
          width: '80%',
          textAlign: 'center'
        }}
      >
        <div className="text-center">
          <div>
            IMFeD has been conducting training and educational CPD events for
            all healthcare professionals since the programme started in 2012.
            Over the years, we have had many credible speakers delivering
            insightful lectures with practical approaches, which also adds value
            to your paediatric practice. You can browse here to view lectures.
          </div>
          {/* <h4>Paediatric Growth & Nutrition Online Course</h4>
        <p>
          Available until Aug 15, 2024. CPD points will be accredited within 2
          months of completion
        </p> */}
        </div>

        {/* <div className="cards-container">
        {moduleData.map(card => (
          <div
            key={card.id}
            className="card-module"
            style={{
              backgroundImage: `linear-gradient(to bottom, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)),
							url(${card.backgroundImage})`,
              objectFit: 'cover',
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover'
            }}
          >
            {card.status !== 'Block' ? (
              <p
                style={{ cursor: 'pointer' }}
                onClick={() => navigate('/askExpert', { state: card.id })}
                className="card-description"
              >
                Ask the expert
                <img style={{ marginLeft: '10px' }} alt="Icon" src={askIcon} />
              </p>
            ) : (
              <p style={{ cursor: 'pointer' }} className="card-description">
                Ask the expert
                <img style={{ marginLeft: '10px' }} alt="Icon" src={askIcon} />
              </p>
            )}
            <div
              className="d-flex flex-column justify-content-between"
              style={{ height: '210px' }}
            >
              <div>
                <div className="card-icon">
                  <img src={moduleIcon} alt="Icon" />
                </div>
                <div className="card-content">
                  <h4 className="card-title">{card.title}</h3>
                  <p className="card-description">{card.description}</p>
                </div>
              </div>
              <div className="card-action">
                {card.status === 'New' ? (
                  <button
                    onClick={() =>
                      navigate('/moduleContent', { state: card.id })
                    }
                  >
                    Enter Module
                  </button>
                ) : card.status === 'Pending' ? (
                  <button
                    style={{ backgroundColor: '#F5A811', color: 'white' }}
                    onClick={() =>
                      navigate('/moduleContent', { state: card.id })
                    }
                  >
                    In Progress
                  </button>
                ) : card.status === 'Complete' ? (
                  <button
                    style={{
                      backgroundColor: '#00BC4B',
                      border: '1px solid #00BC4B',
                      color: '#fff'
                    }}
                    onClick={() =>
                      navigate('/moduleContent', { state: card.id })
                    }
                  >
                    Completed
                  </button>
                ) : (
                  <button>Enter Module</button>
                )}
              </div>
            </div>
          </div>
        ))}
      </div> */}
        <div className="text-center coming-soon-container d-flex flex-column justify-content-between mt-5">
          <h4>Modules</h4>
          <div>Coming soon</div>
          <div></div>
        </div>

        <div>
          <div className="d-flex justify-content-between">
            <h4>Other materials</h4>
            <div>
              <Link to="/materials">View all materials</Link>
            </div>
          </div>
          <div className="coming-soon-grid">
            {materialsData.map((card, index) => (
              <div>
                <img
                  onClick={() =>
                    navigate('/materialsInformation', {
                      state: { id: card.id }
                    })
                  }
                  key={index}
                  src={card.imageURL}
                  className="coming-soon-grid-img"
                  alt="Materials"
                />
                <div className="d-flex mt-2">
                  <div className="coming-soon-material-cat-container">
                    <h6 className="mt-2 coming-soon-material-cat">
                      {card.category}
                    </h6>
                  </div>

                  <p className="ms-2 text-start">{card.Title}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default Module
